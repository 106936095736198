import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-18.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import wetag1 from "../../assets/images/sp/18/wetag-1.jpg";
import wetag2 from "../../assets/images/sp/18/wetag-2.jpg";
import wetag3 from "../../assets/images/sp/18/wetag-3.jpg";
import wetag4 from "../../assets/images/sp/18/wetag-4.jpg";
import wetag5 from "../../assets/images/sp/18/wetag-5.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Wetag Consulting | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 18</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.wetag.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24}} lg={{span: 15}} className="text-justify mb-4">
                                <h6 className="text-primary text-uppercase fw-bold">Agenzia immobiliare di lusso dal 1973</h6>
                                <h1>Wetag Consulting</h1>
                                <h4>La sua agenzia leader nel settore immobiliare di lusso in Ticino, Svizzera</h4>
                                <p>Ci riteniamo fortunati di poter rappresentare i proprietari degli immobili più incantevoli del territorio, tra cui ville mediterranee, icone architettoniche senza tempo, capolavori moderni e attici sofisticati sul Lago di Lugano e sul Lago Maggiore. Siamo orgogliosi di aver assistito personalmente innumerevoli clienti a trovare la casa perfetta per loro e ad adattarsi al nuovo stile di vita ticinese.</p>
                                <p>In qualità di membri delle principali reti immobiliari di lusso internazionali, abbiamo la portata e l'esperienza per fornire ai nostri clienti il miglior servizio e la migliore conoscenza del settore. Siamo spesso invitati a partecipare a congressi e conferenze internazionali annuali e ci assicuriamo di essere sempre aggiornati sulle tendenze e gli sviluppi del mercato immobiliare internazionale.</p>
                                <p>Ci impegniamo a presentare sul mercato le proprietà dei nostri clienti e ad aiutare chi desidera acquistare la propria casa dei sogni.</p>
                                <em>“L’elenco dei clienti Wetag include membri delle famiglie reali, pop star, piloti di F1, artisti famosi, uomini d’affari, politici, ma anche semplici persone della porta accanto provenienti da più di 70 nazioni diverse."</em>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 9}} className="text-center">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={wetag1} alt="Wetag"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                                <h5><b>Philipp Peter & Ueli Schnorf</b></h5>
                                <p className="text-muted fs-6">Proprietari / amministratori delegati Wetag Consulting</p>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container text-center">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24}} lg={{span: 24}} className="text-center">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={wetag2} alt="Wetag"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={wetag3} alt="Wetag"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={wetag4} alt="Wetag"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={wetag5} alt="Wetag"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-secondary">
                    <div className="container text-justify">
                        <h2>Cosa possono aspettarsi i nostri clienti</h2>
                        <h4>Per acquirenti e interessati agli immobili ticinesi</h4>
                        <p>Trovare e acquistare la giusta proprietà nella Svizzera italiana non è sempre facile. Spesso richiede tempo, conoscenza del territorio e dedizione. Ma non si tratta soltanto del giusto oggetto. Occorre anche assicurarsi che il cliente possa condurre lo stile di vita prescelto da lui e dai suoi familiari.</p>
                        <p>Ci prendiamo il tempo per conoscere le esigenze dei nostri clienti. Vi presentiamo le proprietà adatte al vostro stile di vita ideale. Saremo lieti di spiegarvi tutto quello che dovete sapere sui quartieri e le distanze dalle attività e dai luoghi che volete frequentare. Il nostro team vi terrà aggiornati sui movimenti del mercato in modo che possiate prendere una decisione informata. Siamo orgogliosi di deliziare i nostri clienti con proprietà stimolanti e attraenti che soddisfano le loro specifiche esigenze.</p>
                        <Divider/>
                        <h4>Per i venditori di immobili Ticinesi</h4>
                        <p>Ogni anno Wetag ha il privilegio di vendere le più esclusive abitazioni ticinesi ad acquirenti esigenti provenienti da tutto il mondo Uniamo la passione per il nostro lavoro e un’approfondita conoscenza del territorio ad attività di marketing uniche a livello internazionale, che si sono ampliate grazie alla nostra appartenenza alle reti immobiliari più prestigiose a livello internazionale. Il marketing e la vendita di abitazioni di lusso in Ticino richiedono strategie sofisticate e su misura per il singolo cliente. Vi forniamo informazioni dettagliate su come pubblicizziamo la vostra proprietà a un pubblico di potenziali acquirenti provenienti da oltre 70 paesi.</p>
                        <p>Per saperne di più: <a href="https://www.wetag.ch">www.wetag.ch</a></p>
                        <p>Il nostro blog: <a href="https://journal.wetag.ch/">journal.wetag.ch</a></p>
                    </div>
                </section>
                <section>
                    <div className="container text-center">
                        <div className="youtubeContainer">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/QGnsm5uVyH4"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
